import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination, Autoplay, Navigation } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";

const reviews = [
  {
    testimonial:
      "The team of highly skilled technicians at MEKCO has over 50 years of combined electrical repair experience. Our commitment to excellence and quality repairs offers timely delivery, professional technical service, traceable approved parts, and detailed repair documentation with customized support. We can provide you with an alternative to costly OEM repairs and assist with product obsolescence issues, saving you both time and money.",
    thumbnail: "/images/testimonials/02.png",
    name: "David Smith",
    position: "Founder",
  },
];

const Testimonial = () => {
  return (
    <div className="rts-client-feedback testminonial-p rts-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-10 col-xl-8 mx-auto">
            <div className="rts-title-area reviews text-center pl_sm--0 pl_md--30 pt--70">
              <p className="pre-title more-ab-sect-title">Our Testimonials</p>
              <h2 className="title sub-t-back">Client’s Feedbacks</h2>

              <div className="swiper mySwipertestimonial">
                <Swiper
                  pagination={{
                    el: ".custom-pagination",
                    clickable: true,
                  }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  slidesPerView={1}
                  loop={true}
                  autoplay={{
                    delay: 5500,
                  }}
                  modules={[Pagination, Autoplay, Navigation]}
                  className="swiper-wrapper"
                >
                  {reviews.map(
                    ({ testimonial, thumbnail, name, position }, index) => (
                      <SwiperSlide key={`review-${index}`}>
                        <div className="swiper-slide">
                          <div className="testimonial-inner">
                            <p className="disc text-start test-desc text-center">
                              {testimonial}
                            </p>
                            <div className="testimonial-bottom-one">
                              <div className="thumbnail">
                                <img
                                  src={thumbnail}
                                  alt="business-testimonials"
                                />
                              </div>
                              <div className="details">
                                <a href="#">
                                  <h5 className="title tt-name">{name}</h5>
                                </a>
                                <span className="tt-position">{position}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ),
                  )}
                  <div className="swiper-button-prev"></div>
                  <div className="swiper-button-next"></div>
                </Swiper>
              </div>
            </div>
          </div>
          {/* 
          <div className="col-lg-5">
            <div className="rts-test-one-image-inner">
              <img
                src="/images/testimonials/01.png"
                alt="business_testimobials"
              />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
