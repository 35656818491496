import React from "react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Autoplay } from "swiper";
import { SwiperSlide, Swiper } from "swiper/react";

import { useViewport } from "@/hooks/useViewport";
import { BlogContext } from "@/context/BlogContext";
import { Link } from "gatsby";
import { parseDate } from "@/components/Careers/Jobs";
import { format } from "date-fns";

const BlogArea = () => {
  const { isDesktop } = useViewport();
  return (
    <BlogContext.Consumer>
      {({ state }) => {
        return (
          <div className="rts-blog-area pt--80 pb--80 bg-secondary">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="title-area text-center tb-section">
                    <span>Blog Posts</span>
                    <h2 className="title tb-section-sub mb--50">Latest news</h2>
                  </div>
                </div>
              </div>

              <div className="mySwiperh1_blog">
                <Swiper
                  slidesPerView={isDesktop ? 3 : 1}
                  spaceBetween={20}
                  loop={true}
                  autoplay={{
                    delay: 5500,
                  }}
                  modules={[Autoplay]}
                >
                  {state.recentPosts.map((post) => (
                    <SwiperSlide key={post.id}>
                      <div className="single-blog-one-wrapper">
                        <div className="thumbnail">
                          <img src={post.imageUrl} alt="Post Image" />
                          <div className="blog-badge">
                            <span>
                              {format(
                                parseDate(post.createdAt),
                                "MMMM d, yyyy",
                              )}
                            </span>
                          </div>
                        </div>
                        <div className="blog-content">
                          <p>
                            <span>{post.title} </span>/ {`by ${post.author}`}
                          </p>
                          <Link to={`/news/slug/${post.slug}`}>
                            <h5 className="title">{post.title} </h5>
                          </Link>

                          <Link
                            to={`/news/slug/${post.slug}`}
                            className="rts-read-more btn-primary"
                          >
                            <i className="far fa-arrow-right"></i>Read More
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
          </div>
        );
      }}
    </BlogContext.Consumer>
  );
};

export default BlogArea;
