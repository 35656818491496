import React from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

const About = () => {
  const { pathname } = useLocation();
  const isHomePath = pathname === "/";

  return (
    <div className={`about-wrapper ${isHomePath ? "home" : ""}`}>
      <div className="container">
        <div className="about-content-wrapper">
          <div className="rts-title-area ab-us">
            <p className="pre-title">MORE ABOUT US</p>
            <h2 className="title main-title-back">MEKCO GROUP</h2>
            <h3 className="sub-t-back">
              Aircraft cabin & connectivity innovation
            </h3>
          </div>
          <div className="section-2-ab">
            <p className="pa-content mb--10">
              Established in 2008 and headquartered in Miami, Florida, MEKCO
              Group is an FAA and EASA repair facility that specializes in
              maintenance, repair, overhaul and exchange of in-flight
              entertainment and connectivity, cabin and galley equipment
              solutions along with a wide range of plastic and composite
              repairs.
            </p>
            <p className="pa-content">
              The team of highly skilled technicians at MEKCO has over 50 years
              of combined electrical repair experience. Our commitment to
              excellence and quality repairs offers timely delivery,
              professional technical service, traceable approved parts, and
              detailed repair documentation with customized support. We can
              provide you with an alternative to costly OEM repairs and assist
              with product obsolescence issues, saving you both time and money.
            </p>
            {/*   <p className="pa-content">
              Established in 2008 and headquartered in Miami, Florida,{" "}
              <strong>MEKCO Group</strong> is an FAA and EASA repair facility
              that specializes in maintenance, repair, overhaul and exchange of
              in-flight entertainment and connectivity, cabin and galley
              equipment solutions along with a wide range of plastic and
              composite repairs. <br />
              <br />
              The team of highly skilled technicians at <strong>
                MEKCO
              </strong>{" "}
              has over 50 years of combined electrical repair experience. Our
              commitment to excellence and quality repairs offers timely
              delivery, professional technical service, traceable approved
              parts, and detailed repair documentation with customized support.
              We can provide you with an alternative to costly OEM repairs and
              assist with product obsolescence issues, saving you both time and
              money.
            </p> */}
            {/* <p className="pa-content">
              The team of highly skilled technicians at MEKCO has over 50 years
              of combined electrical repair experience. Our commitment to
              excellence and quality repairs offers timely delivery,
              professional technical service, traceable approved parts, and
              detailed repair documentation with customized support. We can
              provide you with an alternative to costly OEM repairs and assist
              with product obsolescence issues, saving you both time and money.
            </p> */}
            <span className="btn-l-m">
              <Link to="/about">Learn More</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
