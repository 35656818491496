import React from "react";
import Capabilities from "../Capabilities";

export const OurServices = () => {
  return (
    <div className="our-services-section rts-bg">
      <div className="container pt--80">
        <div className="rts-title-area our-services">
          <p className="pre-title text-center">OUR SERVICES</p>
          <h2 className="title service-title">High Quality Services</h2>
        </div>
        <Capabilities />
      </div>
    </div>
  );
};
