import { Link } from "gatsby";
import React from "react";

const Hero = () => {
  return (
    <div className="hero-wrapper">
      <div className="container">
        <div className="hero-content-wrapper">
          <h1>Aircraft cabin &</h1>
          <h1>connectivity</h1>
          <h1>innovation</h1>
          <Link className="rts-btn btn-primary text-center" to="/contact">
            Appointment
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Hero;
