import HomePage from "@/components/Home";
import Hero from "@/components/Home2/Hero";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import React from "react";
import { useLocation } from "@reach/router";
import "@/components/Home2/home2.css";
import About from "@/components/Home2/About";
import { HowToVideos } from "@/components/Home2/HowToVideos";
import BlogArea from "@/components/Home/Blog";
import { OurServices } from "@/components/Home2/OurServices";
import { useViewport } from "@/hooks/useViewport";
const Home = () => {
  const { isMobile } = useViewport();
  return (
    <Layout>
      <SEO title="Home" canonical={`${useLocation().host}`} />
      <Hero />
      <About />
      {/*       
      <Services />
       */}
      <HowToVideos />
      <OurServices />
      {!isMobile && <BlogArea />}
      <HomePage />
    </Layout>
  );
};

export default Home;
