import React from "react";
// import ContactArea from "./Contact";
// import About from "./About";
// import BlogArea from "./Blog";
// import Business from "./Business";
// import CounterP from "./Counter";
// import Features from "./Features";
// import Hero from "./Hero";
// import MapArea from "./Map";
import Partners from "./Partners";
// import Portfolio from "./Portfolio";
// import Services from "./Services";
// import Team from "./Team";
import Testimonial from "./Testimonial";
import PartnersQ from "../About2/Quality/Partners";
import { useViewport } from "@/hooks/useViewport";

const HomePage = () => {
  const { isMobile } = useViewport();
  return (
    <>
      {/* <Hero /> */}
      {/* <About /> */}
      {/* <Services /> */}
      {/* <Business /> */}
      {/* <CounterP /> */}
      {/* <Portfolio /> */}
      {isMobile && <PartnersQ />}
      <Partners />
      {/* <Team /> */}
      {/* <Features /> */}
      <Testimonial />
      {/* <BlogArea /> */}
      {/* <ContactArea /> */}
      {/* <MapArea /> */}
    </>
  );
};

export default HomePage;
